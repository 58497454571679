<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box" style="min-width: 700px;">
          <v-tabs v-model="tab">
            <v-tab v-ripple="false">{{ $t('heading.instance.advanced.logs.instanceLogs.title') }}</v-tab>
            <v-tab v-ripple="false">{{ $t('heading.instance.advanced.logs.wordpressLogs.title') }}</v-tab>
            <v-tab v-ripple="false">
              {{ $t('heading.instance.advanced.logs.phpLogs.title') }}
              <v-tooltip
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="4px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    size="16" 
                    v-on="on"
                    v-bind="attrs" 
                    class="ml-2"
                    >
                    $infoCircle
                  </v-icon>
                </template>

                <span>{{ $t('heading.instance.advanced.logs.phpLogs.tooltip') }}</span>
              </v-tooltip>
            </v-tab>
            <v-tab v-ripple="false">{{ $t('heading.instance.advanced.logs.webserverLogs.title') }}</v-tab>
          </v-tabs>
        </v-col>
        <v-col class="page-title__filter-controls align-center">
          <data-iterator-filter-controls
            v-if="tab === 0"
            :showSearchTerm="true"
            :searchTerm="searchTerm"
            @update:searchTerm="handleSearchTermChange"
          ></data-iterator-filter-controls>
          <v-select
            v-if="tab === 3"
            class="dns-zone v-input--md"
            outlined
            :hide-details="true"
            :value="selectedWebserverLogFile"
            v-model="selectedWebserverLogFile"
            :items="webserverLogs"
            item-text="name"
            item-value="name"
            return-object
            :menu-props="{
              offsetY: true,
              nudgeBottom: '8px',
            }"
            @change="handleChangeLogFile"
            small
          >
            <template v-slot:selection="{ item }">
              <div>{{ item.name }}</div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex justify-space-between align-center w-100">
                <div class="text-truncate">{{ item.name }}</div>
                <div class="d-flex align-center">
                  <span v-if="item.size !== null">{{ Helper.formatBytes(item.size) }}</span>
                  <v-btn
                    color="primary"
                    class="ml-4"
                    x-small
                    elevation="0"
                    icon
                    @click="downloadWebserverLogsFile(item.name)"
                  >
                    <v-icon>$downloadcloud</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>

    <v-tabs-items v-model="tab" :class="'overflow-visible'">
      <!-- ACTIVITY LOGS -->
      <v-tab-item :transition="false">
        <v-container class="px-0">
          <v-row>
            <v-col cols="12">
              <logs-table
                :items="itemsLoading ? [] : items"
                :itemsLoading="itemsLoading"
                :itemsPerPage="itemsPerPage"
                :itemsTotal="itemsTotal"
                :headers="headers"
                :page="page"
                :possibleItemsPerPage="[[2, 5, 10, 20, 100]]"
                :searchTerm="searchTerm"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                @update:page="changePage"
                @update:itemsPerPage="changePerPage"
                @update:sortDesc="changeSortDesc"
                @update:sortBy="changeSortBy"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- WORDPRESS ERROR LOGS -->
      <v-tab-item :transition="false">
        <v-container class="px-0">
          <v-row>
            <v-col cols="12">
              <v-card elevation="4" v-if="loadingWordPressErrorLogs" class="pt-15 pb-15 d-flex flex-column align-center justify-center" style="height: 344px;">
                <loader />
                <p class="p-lg font-weight-bold mt-6 mb-0">{{ $t('heading.instance.advanced.logs.loader') }}</p>
              </v-card>
              <v-card v-else-if="!wordPressErrorLogs" elevation="4">
                <logs-no-data style="height: 344px;">
                  <template v-slot:title>{{ $t('message.emptyTable.wpErrorLogs.title') }}</template>
                  <template v-slot:subtitle>{{ $t('message.emptyTable.wpErrorLogs.description') }}</template>
                </logs-no-data>
              </v-card>
              <v-card v-else elevation="4">
                <div class="pl-6 pr-6 pt-3 pb-3 d-flex justify-space-between align-center border-bottom-gray">
                  <p class="d-flex align-center info--text p-xxs font-weight-semibold mb-0">
                    <v-icon size="16" class="mr-2 info--text">$infoCircle</v-icon>
                    {{ $t('notification.instance.advanced.logs.maxEntries') }}
                  </p>
                  <v-btn
                    class="v-size--small"
                    color="primary"
                    elevation="0"
                    @click="downloadWordPressErrorLogs()"
                    small
                  >
                  <v-icon size="16">$downloadcloud</v-icon>
                  {{ $t('button.download') }}
                  </v-btn>
                </div>
                <pre
                  class="pl-6 pr-6 pt-3 pb-3 overflow-x-auto overflow-y-auto text--gray-darken1 p-sm logs-lh"
                  style="max-height: 328px;"
                >{{ wordPressErrorLogs }}</pre>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- PHP ERROR LOGS -->
      <v-tab-item :transition="false">
        <v-container class="px-0">
          <v-row>
            <v-col cols="12">
              <v-card elevation="4" v-if="loadingPhpLogs" class="pt-15 pb-15 d-flex flex-column align-center justify-center" style="height: 344px;">
                <loader />
                <p class="p-lg font-weight-bold mt-6 mb-0">{{ $t('heading.instance.advanced.logs.loader') }}</p>
              </v-card>
              <v-card v-else-if="phpLogs" elevation="4">
                <div v-if="phpLogs" class="pl-6 pr-6 pt-3 pb-3 d-flex justify-space-between border-bottom-gray">
                  <p class="d-flex align-center info--text p-xxs font-weight-semibold mb-0">
                    <v-icon size="16" class="mr-2 info--text">$infoCircle</v-icon>
                    {{ $t('notification.instance.advanced.logs.maxEntries') }}
                  </p>
                  <v-btn
                    class="v-size--small"
                    color="primary"
                    elevation="0"
                    @click="downloadPhpLogs()"
                    small
                  >
                  <v-icon size="16">$downloadcloud</v-icon>
                  {{ $t('button.download') }}
                  </v-btn>
                </div>
                <pre
                  v-if="phpLogs"
                  class="pl-6 pr-6 pt-3 pb-3 overflow-x-auto overflow-y-auto text--gray-darken1 p-sm logs-lh"
                  style="max-height: 328px;"
                >{{ phpLogs }}</pre>
              </v-card>
              <v-card v-else elevation="4">
                <logs-no-data style="height: 344px;">
                  <template v-slot:title>{{ $t('message.emptyTable.phpLogs.title') }}</template>
                  <template v-slot:subtitle>{{ $t('message.emptyTable.phpLogs.description') }}</template>
                </logs-no-data>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

      <!-- WEBSERVER LOGS -->
      <v-tab-item :transition="false">
        <v-container class="px-0">
          <v-row>
            <v-col cols="12">
              <v-card elevation="4">
                <div
                  v-if="!loadingWebserverLogsFile && selectedWebserverLogFile.plain_text && webserverLogsFileContent"
                  class="pl-6 pr-6 pt-3 pb-3 d-flex justify-space-between border-bottom-gray"
                  :class="!loadingWebserverLogsFile && selectedWebserverLogFile.plain_text && webserverLogsFileContent ? 'justify-space-between' : 'justify-end'"
                >
                  <p class="d-flex align-center info--text p-xxs font-weight-semibold mb-0">
                    <v-icon size="16" class="mr-2 info--text">$infoCircle</v-icon>
                    {{ $t('notification.instance.advanced.logs.maxEntries') }}
                  </p>
                  <v-btn
                    color="primary"
                    elevation="0"
                    small
                    @click="downloadWebserverLogsFile(selectedWebserverLogFile.name)"
                  >
                    <v-icon size="16">$downloadcloud</v-icon>
                    {{ $t('button.download') }}
                  </v-btn>
                </div>
                <div style="height: 344px;" v-if="loadingWebserverLogsFile" class="pt-15 pb-15 d-flex flex-column align-center justify-center">
                  <loader />
                  <p class="p-lg font-weight-bold mt-6 mb-0">{{ $t('heading.instance.advanced.logs.loader') }}</p>
                </div>
                <div v-else-if="selectedWebserverLogFile.plain_text">
                  <pre
                    v-if="webserverLogsFileContent"
                    class="pl-6 pr-6 pt-3 pb-3 overflow-x-auto overflow-y-auto text--gray-darken1 p-sm logs-lh"
                    style="max-height: 328px;"
                  >{{ webserverLogsFileContent }}
                  </pre>
                  <logs-no-data v-else style="height: 344px;">
                    <template v-slot:title>{{ $t('message.emptyTable.webserverLogs.title') }}</template>
                    <template v-slot:subtitle>{{ $t('message.emptyTable.webserverLogs.description') }}</template>
                  </logs-no-data>
                </div>
                <logs-no-data v-else  @download-logs="downloadWebserverLogsFile(selectedWebserverLogFile.name)" :btnDownload="true" style="height: 344px;">
                  <template v-slot:title>{{ $t('message.emptyTable.webserverLogs.downloadTitle') }}</template>
                  <template v-slot:subtitle>{{ $t('message.emptyTable.webserverLogs.downloadDescription') }}</template>
                </logs-no-data>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  fetchInstanceLogs, fetchPhpLogs,
  fetchWebserverLogs,
  fetchWebserverLogsFile,
  fetchWordPressErrorLogs
} from "../../../router/fetchData";

import DataIteratorPageMixin from "../../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../../mixins/CustomTablePageMixin";
import LogsNoData from "./LogsNoData.vue";
import LogsTable from "../../../components/dataIterator/tables/LogsTable.vue";
import DataIteratorFilterControls from "../../../components/dataIterator/DataIteratorFilterControls.vue";
import Helper from "@/apis/Helper";
import Loader from "../../../components/Loader.vue";
export default {
  computed: {
    Helper() {
      return Helper
    }
  },
  components: {
    Loader,
    LogsNoData,
    LogsTable,
    DataIteratorFilterControls,
  },
  mixins: [DataIteratorPageMixin, CustomTablePageMixin],
  data: function () {
    return {
      fetching: true,
      headers: [
        {
          text: 'user',
          value: "user",
          sortable: false,
        },
        {
          text: 'actions',
          value: "action",
          sortable: false,
        },
        {
          text: 'date',
          value: "date",
          align: "end",
          sortable: true,
        },
      ],
      sortBy: {
        text: 'date',
        value: "date",
        sortable: true,
      },
      sortDesc: true,
      items: [],
      page: 1,
      itemsPerPage: 10,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,
      applicationId: this.$route.params.id,
      tab: 0,

      loadingWebserverLogs: false,
      webserverLogs: [],
      selectedWebserverLogFile: {},

      loadingWebserverLogsFile: false,
      webserverLogsFileContent: null,

      loadingWordPressErrorLogs: false,
      wordPressErrorLogs: null,

      loadingPhpLogs: false,
      phpLogs: null
    };
  },
  mounted() {
    this.loadInstanceLogs();
    this.loadWebserverLogs();
  },
  methods: {
    async loadInstanceLogs() {
      this.itemsLoading = true;
      let {data, meta, error} = await fetchInstanceLogs(
        this.applicationId,
        this.page,
        this.itemsPerPage,
        this.sortBy.value,
        this.sortDesc,
        this.searchTerm
      );
      this.itemsLoading = false;
      if (error) {
        // console.error("ERROR: ", error);
        return;
      }
      this.itemsTotal = meta.total;
      this.items = data;
    },
    async loadWebserverLogs() {
      this.loadingWebserverLogs = true;
      let {data, error} = await fetchWebserverLogs(this.applicationId);
      this.loadingWebserverLogs = false;
      if (error) {
        await this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: error,
        });
        return;
      }
      this.webserverLogs = data;
      this.selectedWebserverLogFile = this.webserverLogs[0];
    },
    async loadWebserverLogsFileContent() {
      this.loadingWebserverLogsFile = true;
      let {data, error} = await fetchWebserverLogsFile(this.applicationId, this.selectedWebserverLogFile.name);
      this.loadingWebserverLogsFile = false;
      if (error) {
        await this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: error,
        });
        return;
      }
      this.webserverLogsFileContent = data;
    },
    downloadWebserverLogsFile(filename) {
      // eslint-disable-next-line
      let apiUrl = this.$store.state.env.apiUrl;
      window.open(
        `${apiUrl}/instances/${this.applicationId}/webserver-logs/${filename}/download`,
        "_blank"
      );
    },
    async loadWordPressErrorLogs() {
      this.loadingWordPressErrorLogs = true;
      let {data, error} = await fetchWordPressErrorLogs(this.applicationId);
      this.loadingWordPressErrorLogs = false;
      if (error) {
        await this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: error,
        });
        return;
      }
      this.wordPressErrorLogs = data;
    },
    downloadWordPressErrorLogs() {
      // eslint-disable-next-line
      let apiUrl = this.$store.state.env.apiUrl;
      window.open(
        `${apiUrl}/instances/${this.applicationId}/wordpress/logs/download`,
        "_blank"
      );
    },
    async loadPhpLogs() {
      this.loadingPhpLogs = true;
      let {data, error} = await fetchPhpLogs(this.applicationId);
      this.loadingPhpLogs = false;
      if (error) {
        await this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: error,
        });
        return;
      }
      this.phpLogs = data;
    },
    downloadPhpLogs() {
      // eslint-disable-next-line
      let apiUrl = this.$store.state.env.apiUrl;
      window.open(
        `${apiUrl}/instances/${this.applicationId}/php-logs/download`,
        "_blank"
      );
    },
    changePage(page) {
      this.page = page;
      this.loadInstanceLogs();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadInstanceLogs();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.loadInstanceLogs();
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.loadInstanceLogs();
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadInstanceLogs();
    },
    handleChangeLogFile() {
      if (this.tab === 3 && this.selectedWebserverLogFile.plain_text) {
        this.loadWebserverLogsFileContent()
      }
    }
  },
  watch: {
    tab(value) {
      if (value === 1 && this.wordPressErrorLogs === null) {
        this.loadWordPressErrorLogs();
      }

      if (value === 2 && this.phpLogs === null) {
        this.loadPhpLogs();
      }

      if (value === 3) {
        if (this.selectedWebserverLogFile.plain_text) {
          this.loadWebserverLogsFileContent();
        }
      }
    },
  },
  beforeRouteEnter: function (_, __, next) {
    next((vm) => {
      vm.fetching = false;
    });
  },
};
</script>
<style lang="scss">
  .font-weight-semibold{
    font-weight: 600!important;
  }
  .border-bottom-gray{
    border-bottom: 1px solid map-get($gray, lighten2);
  }
  .logs-lh{
    line-height: 24px;
  }
  .v-tab{
    svg{
      path{
        transition: 0.3s;
      }
    }
    &.v-tab--active{
      svg{
        path{
          stroke: var(--v-primary-darken1);
        }
      }
    }
    &:hover{
      svg{
        path{
          stroke: var(--v-primary-base);
        }
      }
    }
  }
</style>